import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { Layout } from '../components/Layout'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { filterHtml } from '../utils/filterHtml';
import { PropTypes } from 'prop-types'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

const BlogOverview = ({ data, pageContext }) => {
  const { t } = useTranslation();

	pageContext.title = t('Blog');
  pageContext.type = 'blog_overview'

  const {lang} = pageContext

  const level2Items = data.allPrismicLevel2.nodes;
  const [level2ItemsShown, setLevel2ItemsShown] = React.useState(data.allPrismicLevel2.nodes);
  const [level2Highlight, setLevel2Highlight] = React.useState(data.allPrismicLevel2.nodes[0])
  const [mappedFields] = React.useState({
    titles: data.allPrismicLevel2.nodes.map((item) => (item.data.title.text.toLowerCase())),
    abstracts: data.allPrismicLevel2.nodes.map((item) => (item.data.abstract.html.toLowerCase())),
    uids: data.allPrismicLevel2.nodes.map((item) => (item.uid)),
  })

  const updateFilter = React.useCallback((value) => {
    let pages = [];
    for (let i = 0; i < mappedFields.titles.length; i++) {
      if (value === "" || mappedFields.titles[i].includes(value.toLowerCase()) || mappedFields.abstracts[i].includes(value.toLowerCase())) {
        pages.push(level2Items[i]);
      }
    }
    setLevel2Highlight(pages[0]);
    setLevel2ItemsShown(pages.slice(1));
  }, [level2Items, mappedFields.abstracts, mappedFields.titles])

	React.useEffect(() => {
    updateFilter("");
	}, [updateFilter]);
  
  return (
		<Layout activeDocMeta={pageContext}>
			<div className="o-wrapper c-intro">
				<h1 className="c-intro-block__title">{t('Blog')}</h1>
			</div>
			<div className="o-wrapper c-navigation">
            <form className="form-block basic-form filter-block o-1-col">
              <div className="form-field">
                <div className="form-field-icon field-search">
                  <input type="search" placeholder={t('Zoek een artikel')} name="Trefwoord" id="search" autoComplete="off" onInput={(e) => { updateFilter(e.target.value) }} />
                  <span className="field-icon icon-search" />
                </div>
              </div>
            </form>
            {level2Highlight && (
              <div className="o-2-col -sidearea c-navigation-block -blog">
                <article className="c-navigation-block__item">
                  <h3 className="c-navigation-block__title">
                    <Link className="o-routing-link secondary" to={level2Highlight.url}>{level2Highlight.data.title.text}</Link>
                  </h3>
                  <div className="blog-info">{level2Highlight.data.author_name ? level2Highlight.data.author_name + " - " : ""}{new Date(level2Highlight.data.blog_date || level2Highlight.first_publication_date).toLocaleDateString(lang, { year: 'numeric', month: 'long', day: 'numeric' })}</div>
                  {level2Highlight.data.abstract.html && <div className="c-navigation-block__content" dangerouslySetInnerHTML={{ __html: filterHtml(level2Highlight.data.abstract.html) }} />}
                </article>
                <div className="visual is-sidearea">
                  <img src="/blog-overview-visual.svg" alt="blog overview visual" />
                </div>
              </div>
            )}
				<div className="o-3-col c-navigation-block -blog">
					{ level2ItemsShown.map((value) => {
						return (
							<article className={`c-navigation-block__item`} key={value.id}>
								<h3 className="c-navigation-block__title">
									<Link className="o-routing-link secondary" to={value.url+(value.type === "learning_overview" ? "#welkom" : "")}>{value.data.title.text}</Link>
								</h3>
                <div className="blog-info">{value.data.author_name ? value.data.author_name + " - " : ""}{new Date(value.data.blog_date || value.first_publication_date).toLocaleDateString(lang, { year: 'numeric', month: 'long', day: 'numeric' })}</div>
								{value.data.abstract?.html && <div className="c-navigation-block__content" dangerouslySetInnerHTML={{ __html: filterHtml(value.data.abstract.html) }} />}
								{!value.data.abstract?.html && value.data.description?.html && <div className="c-navigation-block__content" dangerouslySetInnerHTML={{ __html: filterHtml(value.data.description.html) }} />}
							</article>
						)
					})}
				</div>
			</div>
		</Layout>
	)
}

BlogOverview.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object
}


export const query = graphql`
  query blogOverviewQuery(
    $locale: String
  ) {
    allPrismicLevel2(
      filter: { data: { blog: { eq: true } } }
      sort: {fields: [data___blog_date, first_publication_date], order: [DESC, DESC]}
    ) {
      nodes {
        _previewable
        type
        url
        id
        uid
        data {
          title {
            text
          }
          blog_date
          author_name
          abstract {
            html
          }
          types {
            tag_type
          }
        }
        first_publication_date
      }
    }
    locales: allLocale(filter: { language: { eq: $locale } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default withPrismicPreview(BlogOverview)
